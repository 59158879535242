<label *ngIf="label" class="form-label file-field-label">{{ label }}</label>

<section class="file-field">
  <div class="file-field--wrapper">
    <aw-badge
      *ngFor="let file of files"
      [size]="Size.m"
      [label]="file.name"
      [removable]="removable"
      [color]="Color.Blue"
      (onRemove)="onRemove.emit(file)"
    >
      <aw-file-icon [extension]="file.extension" [size]="Size.s" />
    </aw-badge>

    <div class="file-field--upload">
      <aw-icon-button
        [icon]="'upload'"
        [size]="Size.m"
        (click)="fileInput.click()"
      />
      <input
        #fileInput
        type="file"
        class="file-field--upload-input"
        (change)="onUpload.emit($event)"
      />
    </div>
  </div>
</section>
