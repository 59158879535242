<aw-modal
  #modal
  [title]="q.translations.common.feedback.title"
  (hiding)="hide()"
>
  <p class="aw-2-mb--l">{{ q.translations.common.feedback.subtitle }}</p>

  <form [formGroup]="ticketForm">
    <aw-text-field
      class="aw-2-d--block aw-2-mb--l"
      [control]="ticketForm.get('subject')"
      [label]="q.translations.common.feedback.subject"
      [placeholder]="q.translations.common.feedback.subjectPlaceholder"
      [autoFocus]="true"
      [errorText]="formErrors.subject"
    />

    <aw-dropdown-field
      class="aw-2-d--block aw-2-mb--l"
      [options]="types"
      [value]="ticketForm.get('type').value"
      [label]="q.translations.common.feedback.type"
      [placeholder]="q.translations.common.feedback.typePlaceholder"
      [fullWidth]="true"
      (changed)="ticketForm.get('type').setValue($event.value)"
    />

    <aw-multiline-text-field
      class="aw-2-d--block aw-2-mb--l"
      [control]="ticketForm.get('description')"
      [label]="q.translations.common.feedback.description"
      [placeholder]="q.translations.common.feedback.descriptionPlaceholder"
      [errorText]="formErrors.description"
    />
  </form>

  <!-- The files attachment will be re-enabled when we'll find a way to upload images on intercom -->
  <aw-file-field
    [label]="q.translations.FileAttachmentComponent.title"
    [files]="filesVisualization"
    [removable]="false"
    (onUpload)="fileChangeListener($event)"
  />

  <div modal-footer>
    <aw-button
      [type]="'tertiary'"
      [text]="q.translations.common.close"
      (onClick)="hide()"
    />

    <aw-button
      [type]="'primary'"
      [isSubmit]="true"
      [formId]="'ticketForm'"
      [text]="q.translations.common.feedback.fileTicket"
      [disabled]="isFileUploading"
      [state]="isLoading ? 'loading' : isSuccess ? 'success' : 'default'"
      [loadingText]="q.translations.common.saving"
      (onClick)="sendTicket()"
    />
  </div>
</aw-modal>
