export function getSubDetailRoute(url: string): string {
  // if there was a detail routing, preserve it
  const detailRoute = url.substring(url.indexOf('(detail:'), url.length - 1)
  const routeSegments: string[] = detailRoute.split('/')
  routeSegments.shift()
  return routeSegments.length > 0 ? '/' + routeSegments.join('/') : ''
}

/**
 * Masks a url to get rid of all ids, to be groupable for tracking etc.
 * @param url
 */
export function maskUrl(url: string): string {
  // Mask Ids from URL
  url = url.replace(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[[0-9a-f]{4}-[0-9a-f]{12}/gi, '{id}')

  // Remove query params from URL
  if (url.includes('?')) {
    url = url.substring(0, url.indexOf('?'))
  }

  return url
}
